import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contactbox from "../components/contact/contactbox"
import { Link } from "gatsby"
import CategoryTitle from "../components/index/categorytitle"

const Contact = () => (
  <Layout>
    <SEO title="İletişim|Связь|Contact" />
    <div id="contact" className="inlinePage footerFixed">
      <div className="container-fluid w80 ma pt5 col-xs-pt15 contentFixed center-h">
      	<div className="center-content w100 left">
      		<CategoryTitle title="SERENAY" subTitle="Contact us" description="Contact us" isDark={false} position="inline" />
      		<div className="contact1 w100 left soft">
		        <div className="w100 left">

		        	<Contactbox 
		        		size=" col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 "
			        	icon="customer1"
			         	title="Get Support"
			         	description="You can send all your support requests to destek@serenay.net.tr."
			         	mail="destek@serenay.net.tr"
			         	buttonicon="link"
			        />

			        <Contactbox 
			        	size=" col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 "
			        	icon="work"
			         	title="Customer Panel"
			         	description="Check out your 'Customer Area' to manage your products &amp; services. "
			         	href="https://www.serenay.net.tr/panel/"
			         	buttonicon="link"
			        />

			        <Contactbox 
			        	size=" col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 "
			        	icon="serenay"
			         	title="Corporate identity"
			         	description="Images for you to use in your company-related content"
			         	linkTo="/corporate/"
			         	buttonicon="search2"
			        />
			        <div className="adressline w100 left pt15 mt15 bc1 p15 col-xs-pl0 col-xs-pr0" style={{borderTop:'1px dashed'}}>
		        	<ul className="w75 col-xs-w100 left mb0 col-xs-mb25 col-xs-t-center">
		        		<li className="w100 left fw4 colored3 fs18">
		        			<i className="icon icon-marker left mr7 lh28 col-xs-w100 col-xs-t-center col-xs-mb7 col-xs-mr0 col-xs-fs24"></i>
		        			Konak, Elit Park, Kudret Sk. No: 12 D:Kat 3 Daire 17, 16140 <b className="fw5">Nilüfer/Bursa</b>
		        		</li>
		        		<li className="w100 left fw4 colored3 fs18 mt10">
		        			<div className="wi left mr25 col-xs-mri col-xs-table col-xs-ma col-xs-fn">
		        				<i className="icon icon-phone left mr7 lh28 fs16"></i>
		        				<a className="colored3 fw6" href="tel:[+902244138676]">+90 224 413 86 76</a>
		        			</div>	
		        			<div className="wi left mr25 col-xs-mri col-xs-table col-xs-ma col-xs-fn">
		        				<i className="icon icon-mail2 left mr7 lh28 fs16"></i>
		        				<a className="colored3 fw6" href="mailto:destek@serenay.net.tr"> destek@serenay.net.tr</a>
		        			</div>	        			
		        		</li>
		        		<li className="w100 left mt15">
		        		<ul>
					    		<li>
					    			<ul className="flex">
					    				<li className="mr15">
					    					<Link className="colored-bg white pl15 pr15 lh30 b-ra5 in-block" to="/security">Privacy & Security</Link>
					    				</li>
					    			</ul>
					    		</li>
					    	</ul>
		        		</li>
		        	</ul>
		        	<a href="https://goo.gl/maps/kEsMupi2Wgci2rGm6" target="_blank" rel="noopener noreferrer" className="colored2-bg white right p25 b-ra3 fs13 fw5 cur-p col-xs-table col-xs-wi col-xs-ma col-xs-fn formlink"><i className="icon icon-marker left mr7 fs15 lh16"></i>SHOW ON MAP</a>
		        </div>
		        </div>
	        </div>
      	</div>
        
        
      </div>
	    <div id="" className="harita_alani w100 left h80h center-h col-xs-none">
		    <div className="container col-xs-w75">		    	
		    	<a className="mapcontent w100 left soft" rel="noopener noreferrer" target="_blank" href="https://goo.gl/maps/kEsMupi2Wgci2rGm6" style={{ height: "47vh" }}>

					{/* === Buraya GEri Dön Fake Map Yap === */}

				</a>
		    </div>
	    </div>
    </div>
  </Layout>
)

export default Contact
