import React from "react"
import { Link } from "gatsby"
import Icon from "../icon/icon";

const Contactbox = (props) => {


if (props.href) {
    return (
      <div className={'p15 left b-ra3 mb10 col-xs-pl0 col-xs-pr0 item ' + props.size}>
        <a className="box w100 left colored3 b-ra10 bs3 p35 t-center fs18 fw4 colored5-bg" href={props.href}>
            <Icon icon={props.icon} classAdd={'icon fs45 soft markicon icon-'}></Icon>
            <div className="w100 left t-center mt10 fs24 fw6 wsn title">{props.title}</div>
            <div className="w100 center-w t-center pt10 pb15 description">{props.description}</div>
            <div className="cur-p center-w p10 b-ra5 colored3 bb1 bl1 br1 bt1">
                <i className={'icon fs16 lh16 left soft icon-' + props.buttonicon}></i>
            </div>
        </a>
    </div>
    )
  }

  if (props.mail) {
    const mailto = 'mailto:'+props.mail;
    return (
      <div className={'p15 left b-ra3 mb10 col-xs-pl0 col-xs-pr0 item ' + props.size}>
        <a className="box w100 left colored3 b-ra10 bs3 p35 t-center fs18 fw4 colored5-bg" href={mailto}>
            <Icon icon={props.icon} classAdd={'icon fs45 soft markicon icon-'}></Icon>
            <div className="w100 left t-center mt10 fs24 fw6 wsn title">{props.title}</div>
            <div className="w100 center-w t-center pt10 pb15 description">{props.description}</div>
            <div className="cur-p center-w p10 b-ra5 colored3 bb1 bl1 br1 bt1">
                <i className={'icon fs16 lh16 left soft icon-' + props.buttonicon}></i>
            </div>
        </a>
    </div>
    )
  }

  return (
    <div className={'p15 left b-ra3 mb10 col-xs-pl0 col-xs-pr0 item ' + props.size}>
        <Link className="box w100 left colored3 b-ra10 bs3 p35 t-center fs18 fw4 colored5-bg" to={props.linkTo}>
            <Icon icon={props.icon} classAdd={'icon fs45 soft markicon icon-'}></Icon>
            <div className="w100 left t-center mt10 fs24 fw6 wsn title">{props.title}</div>
            <div className="w100 center-w t-center pt10 pb15 description">{props.description}</div>
            <div className="cur-p center-w p10 b-ra5 colored3 bb1 bl1 br1 bt1">
                <i className={'icon fs16 lh16 left soft icon-' + props.buttonicon}></i>
            </div>
        </Link>
    </div>
  )
}
export default Contactbox